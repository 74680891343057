import { graphql, useStaticQuery } from "gatsby";
import React, { ReactElement } from "react";
import { Box } from "rebass";
import styled from "styled-components";

const Hr = styled(Box)`
  width: 100%;
  height: 0;
  border-top: 1px solid white;
`;

const Footer = (): ReactElement => {
  const {
    site: {
      siteMetadata: { author },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);

  return (
    <Box my={3}>
      <Hr mb={3} />© {new Date().getFullYear()} {author}
    </Box>
  );
};

export default React.memo(Footer);

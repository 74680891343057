import { StaticQuery, graphql } from "gatsby";
import { get } from "lodash";
import React, {
  ComponentProps,
  ComponentType,
  FunctionComponent,
  ReactElement,
} from "react";

interface Language {
  value: string;
  label: string;
  short_label: string;
}

export interface LanguageData {
  [id: string]: Language;
}

function injectLanguage<T extends ComponentType<P>, P = ComponentProps<T>>(
  CpntClass: T
): (props: P) => ReactElement;
function injectLanguage<P extends object>(
  CpntClass: FunctionComponent<P>
): (props: P) => ReactElement {
  const languageInjectedComponent = (props: P): ReactElement => (
    <StaticQuery
      query={graphql`
        {
          allLanguagesYaml {
            edges {
              node {
                value
                label
                short_label
              }
            }
          }
        }
      `}
      render={(
        rawData: CollectionQuery<Language, "allLanguagesYaml">
      ): ReactElement => {
        const data: LanguageData = (get(
          rawData,
          "allLanguagesYaml.edges",
          []
        ) as (typeof rawData)["allLanguagesYaml"]["edges"])
          .map(({ node }) => ({ [node.value]: node }))
          .reduce((acc, obj) => {
            return Object.assign(acc, obj);
          }, {});
        return <CpntClass languageData={data} {...props} />;
      }}
    />
  );
  return languageInjectedComponent;
}

export default injectLanguage;

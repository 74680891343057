import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import Helmet from "react-helmet";

interface Props {
  description?: string;
  lang?: string;
  meta?: { name: string; content: string };
  keywords?: string[];
  title?: string;
}

const SEO = ({
  description,
  lang,
  meta,
  keywords,
  title,
}: Props): ReactElement => {
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const metaDescription = description || data.site.siteMetadata.description;
  const siteTitle = `${data.site.siteMetadata.title} 二階堂伊奧`;
  const renderedTitle = !title ? siteTitle : `${title} | ${siteTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={renderedTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: renderedTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: renderedTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

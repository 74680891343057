import { WindowLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { Box, Flex, Text } from "rebass";
import styled from "styled-components";

import injectLanguage, { LanguageData } from "../components/injectLanguage";
import { getLanguageFromPath } from "../helpers/languageHelper";
import Link from "./link";

const Nav = (props): ReactElement => (
  <Flex
    as="nav"
    flexDirection="row"
    justifyContent={["space-between", "flex-end"]}
    {...props}
  />
);

const MainMenuItems = (props): ReactElement => (
  <Flex
    as="ul"
    ml={-3}
    my={0}
    p={0}
    flexWrap="wrap"
    justifyContent="space-between"
    alignItems="center"
    {...props}
  />
);

const StyledMainMenuItems = styled(MainMenuItems)`
  height: 100%;
`;

const LanguageMenuItems = (props): ReactElement => (
  <StyledMainMenuItems
    flexWrap="none"
    justifyContent="flex-start"
    ml={3}
    {...props}
  />
);

const MainMenuItem = ({ to, children, ...restProps }): ReactElement => (
  <Box as="li" p={0} mx={3} my={0} {...restProps}>
    <Link to={to} activeClassName="active">
      {children}
    </Link>
  </Box>
);

MainMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const StyledMainMenuItem = styled(MainMenuItem)`
  display: inline-block;
  text-transform: uppercase;

  a {
    color: rgba(255, 255, 255, 0.85);

    &.active {
      color: white;
      font-weight: 600;
    }
  }
`;

const LanguageMenuItem = (props): ReactElement => (
  <StyledMainMenuItem px={1} mx={0} {...props} />
);

const StyledLanguageMenuItem = styled(LanguageMenuItem)`
  white-space: nowrap;
  /*
  border-left: 1px solid white;

  &:first-child {
    border-left: 0 none;
  }
  */
`;

const MainMenu = ({
  location,
  languageData,
}: {
  location: WindowLocation;
  languageData?: LanguageData;
}): ReactElement => {
  const data = useStaticQuery(graphql`
    {
      allSitePage(
        filter: {
          pluginCreator: { name: { eq: "default-site-plugin" } }
          context: { type: { regex: "/^(?!(home|post)$)/" } }
        }
      ) {
        edges {
          node {
            path
            context {
              id
              title
              category
              lang
              type
            }
          }
        }
      }
      site {
        siteMetadata {
          nonSourcedPages
        }
      }
    }
  `);

  const lang = getLanguageFromPath(location.pathname);

  const pages = get(data.allSitePage, "edges", [])
    .filter(({ node: { context } }): boolean => context.lang === lang)
    .map(
      ({ node: { context, path } }): { title: string; slug: string } => ({
        title: context.title,
        slug: path,
      })
    );

  return (
    <Nav>
      <StyledMainMenuItems>
        {pages.map(
          (page, i): ReactElement => (
            <StyledMainMenuItem key={`item-${i}`} to={page.slug}>
              <Text fontSize={2}>{page.title}</Text>
            </StyledMainMenuItem>
          )
        )}
      </StyledMainMenuItems>
      <LanguageMenuItems>
        {Object.values(languageData)
          .sort(
            (lhs, rhs): number =>
              lhs.value < rhs.value ? 1 : lhs.value > rhs.value ? -1 : 0
          )
          .filter((language): boolean => language.value !== lang)
          .map(
            (language, i): ReactElement => (
              <React.Fragment key={`item-${language.value}`}>
                {i > 0 ? (
                  <span
                    style={{
                      height: "1.5ex",
                      width: "1px",
                      background: "white",
                    }}
                  />
                ) : null}
                <StyledLanguageMenuItem
                  to={location.pathname.replace(
                    new RegExp(`^\/${lang}\/`),
                    `/${language.value}/`
                  )}
                >
                  <Text fontSize={2}>{language.short_label}</Text>
                </StyledLanguageMenuItem>
              </React.Fragment>
            )
          )}
      </LanguageMenuItems>
    </Nav>
  );
};

MainMenu.propTypes = {
  languageData: PropTypes.any,
  location: PropTypes.any.isRequired,
};

export default injectLanguage(MainMenu);

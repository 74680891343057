import { Location, WindowLocation } from "@reach/router";
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby";
import { find, get, pick } from "lodash";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { getLanguageFromPath } from "../helpers/languageHelper";

const StyledLink = styled(GatsbyLink)`
  text-decoration: none;
`;

interface PostSitePage {
  context: {
    id: string;
    lang: string;
    pagePath: string;
  };
}

interface CategorySitePage {
  context: {
    lang: string;
    category: string;
    pagePath: string;
  };
}

const usePageQuery = (): CollectionQuery<PostSitePage, "allSitePage"> &
  CollectionQuery<CategorySitePage, "allCatetorySitePage"> =>
  useStaticQuery(graphql`
    {
      allSitePage(
        filter: { context: { pagePath: { ne: null }, id: { ne: null } } }
      ) {
        edges {
          node {
            context {
              id
              lang
              pagePath
            }
          }
        }
      }
      allCatetorySitePage: allSitePage(
        filter: { context: { pagePath: { ne: null }, category: { ne: null } } }
      ) {
        edges {
          node {
            context {
              lang
              category
              pagePath
            }
          }
        }
      }
    }
  `);

const usePostSitePageQuery = (): CollectionQuery<PostSitePage, "allSitePage"> =>
  pick(usePageQuery(), "allSitePage");

const useCategorySitePageQuery = (): CollectionQuery<
  CategorySitePage,
  "allCatetorySitePage"
> => ({ allCatetorySitePage: usePageQuery().allCatetorySitePage });

const PostLink = ({ articleId, ...restProps }): ReactElement => {
  const allPages = usePostSitePageQuery().allSitePage;

  return (
    <Location>
      {({ location }: { location: WindowLocation }): ReactElement => {
        const lang = getLanguageFromPath(location.pathname);
        const edge = find(
          allPages.edges,
          (edge): boolean =>
            get(edge, "node.context.lang") === lang &&
            get(edge, "node.context.id") === articleId
        );
        const path = get(edge, "node.context.pagePath");
        if (path === undefined) {
          return null;
        } else {
          return <StyledLink to={`/${path}`} {...restProps} />;
        }
      }}
    </Location>
  );
};

PostLink.propTypes = {
  articleId: PropTypes.string.isRequired,
};

const PurePostLink = React.memo(PostLink);

const CategoryLink = ({ category, ...restProps }): ReactElement => {
  const allPage = useCategorySitePageQuery().allCatetorySitePage;

  return (
    <Location>
      {({ location }: { location: WindowLocation }): ReactElement => {
        const lang = getLanguageFromPath(location.pathname);
        const edge = find(
          allPage.edges,
          (edge): boolean =>
            get(edge, "node.context.lang") === lang &&
            get(edge, "node.context.category") === category
        );
        const path = get(edge, "node.context.pagePath");
        if (path === undefined) {
          return null;
        } else {
          return <StyledLink to={`/${path}`} {...restProps} />;
        }
      }}
    </Location>
  );
};

CategoryLink.propTypes = {
  category: PropTypes.string.isRequired,
};

const PureCategoryLink = React.memo(CategoryLink);

export { PurePostLink as PostLink, PureCategoryLink as CategoryLink };
export default StyledLink;

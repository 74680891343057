import { Box } from "rebass";
import styled from "styled-components";

const AspectRatioWrapper = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    padding-top: ${props => (props.ratio ? 100 / props.ratio + "%" : "56.25%")};
  }
  > * {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default AspectRatioWrapper;

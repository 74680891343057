import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { Box } from "rebass";
import styled from "styled-components";

const ContentWrapper = ({ fullWidth, ...restProps }): ReactElement => (
  <Box mx="auto" px={fullWidth ? 0 : 4} {...restProps} />
);

ContentWrapper.propTypes = {
  fullWidth: PropTypes.bool.isRequired,
};

const StyledContentWrapper = styled(ContentWrapper)`
  max-width: ${(props): string => (props.fullWidth ? "none" : "1024px")};
  width: 100%;
`;

StyledContentWrapper.propTypes = {
  fullWidth: PropTypes.bool,
};

StyledContentWrapper.defaultProps = {
  fullWidth: false,
};

export default StyledContentWrapper;

import "./post.gallery.scss";

import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { PureComponent, ReactElement } from "react";
import Slider from "react-slick";
import { Flex } from "rebass";

import AspectRatioWrapper from "../components/aspectRatioWrapper";
import Layout from "../components/layout";
import SEO from "../components/seo";
import componentStyles from "./post.module.scss";

class GalleryTemplate extends PureComponent<{
  data: { [key: string]: any };
  location?: WindowLocation;
}> {
  private ref: React.RefObject<HTMLDivElement>;
  private resizeHandler: () => void;

  public constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.resizeHandler = debounce(this.fitGallery, 250, { maxWait: 250 });
  }

  public render(): ReactElement {
    const { data, location } = this.props;
    const {
      excerpt,
      html,
      frontmatter: { title, images },
    } = data.markdownRemark;

    return (
      <Layout location={location}>
        <SEO title={title} description={excerpt} />
        <h1>{title}</h1>
        <Flex mx={[-4, -4, 0]} flexDirection="column">
          {images && (
            <Slider
              arrows={false}
              dots={true}
              infinite={true}
              autoplay={true}
              autoplaySpeed={3000}
              adaptiveHeight={true}
              lazyLoad="progressive"
            >
              {images.map(({ image }, i) => (
                <AspectRatioWrapper ratio={3 / 2} key={i}>
                  <img src={image} style={{ objectFit: "contain" }} />
                </AspectRatioWrapper>
              ))}
            </Slider>
          )}
        </Flex>
        <div
          ref={this.ref}
          className={componentStyles.wrapper}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </Layout>
    );
  }

  public componentDidMount(): void {
    if (this.hasGallery()) {
      this.fitGallery();
      window.addEventListener("resize", this.resizeHandler, false);
    }
  }

  public componentDidUpdate(): void {
    if (this.hasGallery()) {
      this.fitGallery();
      window.addEventListener("resize", this.resizeHandler, false);
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this.resizeHandler, false);
  }

  private hasGallery = (): boolean => {
    const { data } = this.props;
    const { html } = data.markdownRemark;
    return html ? html.indexOf("kg-gallery-container") !== -1 : false;
  };

  private convertRemToPixels = (rem: number): number =>
    rem *
    parseFloat(getComputedStyle(window.document.documentElement).fontSize);

  private reflowGallery = () => {
    if (this.ref.current) {
      const container = this.ref.current.querySelector(
        ".kg-gallery-container"
      ) as HTMLElement;
      if (container) {
        const rows = Array.from(container.querySelectorAll(".kg-gallery-row"));
        let cnts = [];
        if (container.dataset.rows === undefined) {
          cnts = rows.reduce(
            (acc, row) => [
              ...acc,
              row.querySelectorAll(".kg-gallery-image img").length,
            ],
            []
          );
          container.dataset.rows = cnts.join(",");
        } else {
          cnts = container.dataset.rows
            .split(",")
            .map(n => parseInt(n, 10))
            .filter(n => !Number.isNaN(n));
        }

        const images = Array.from(
          container.querySelectorAll(".kg-gallery-row .kg-gallery-image")
        );

        const reflowImages = (
          cnt: ((n: number) => number) | number[]
        ): void => {
          const getCnt = Array.isArray(cnt)
            ? i => (i < cnt.length ? cnt[i] : cnt[cnt.length - 1])
            : cnt;

          let n = 0;
          rows.forEach((row, i) => {
            if (n >= images.length) {
              row.parentNode.removeChild(row);
            } else {
              for (let len = 0; len < getCnt(i) && n < images.length; ++len) {
                row.appendChild(images[n++]);
              }
            }
          });
          while (n < images.length) {
            const row = document.createElement("div");
            row.className = "kg-gallery-row";
            for (
              let len = 0;
              len < getCnt(rows.length) && n < images.length;
              ++len
            ) {
              row.appendChild(images[n++]);
            }
            rows[0].parentNode.appendChild(row);
          }
        };

        if (window.innerWidth >= this.convertRemToPixels(64)) {
          // Reset all
          reflowImages(cnts);
        } else if (window.innerWidth >= this.convertRemToPixels(52)) {
          reflowImages(cnts.map(n => (n > 3 ? 3 : n)));
        } else if (window.innerWidth >= this.convertRemToPixels(40)) {
          reflowImages(cnts.map(n => (n > 2 ? 2 : n)));
        } else {
          reflowImages(cnts.map(n => (n > 1 ? 1 : n)));
        }
      }
    }
  };

  private fitGallery = (): void => {
    if (this.ref.current) {
      this.reflowGallery();
      const container = this.ref.current.querySelector(".kg-gallery-container");
      if (container) {
        if (container.className.search(/(^| )height-calculated( |$)/) === -1) {
          container.className += " height-calculated";
        }
        const rows = Array.from(container.querySelectorAll(".kg-gallery-row"));
        rows.forEach((row: HTMLElement) => {
          const images = Array.from(
            row.querySelectorAll(".kg-gallery-image img")
          );
          if (images.length > 0) {
            const padding = 15; // px
            const norminalHeight =
              (container.getBoundingClientRect().width -
                padding * (images.length - 1)) /
              images.reduce(
                (acc, img) =>
                  acc +
                  parseInt(img.getAttribute("width"), 10) /
                    parseInt(img.getAttribute("height"), 10),
                0
              );
            row.dataset.norminalHeight = `${norminalHeight}`;
            row.style.height = norminalHeight + "px";
          } else {
            row.dataset.norminalHeight = "0";
          }
        });
      }
    }
  };

  public static propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        layout
        images {
          image
        }
      }
      html
      excerpt
      fileAbsolutePath
    }
  }
`;

export default GalleryTemplate;

import "./layout.css";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";

import { getLanguageFromPath } from "../helpers/languageHelper";
import ContentWrapper from "./contentWrapper";
import Footer from "./footer";
import Header from "./header";

const Main = styled(Box).attrs({ as: "main", pt: 3 })`
  border-top: ${({ fullWidth }) => (fullWidth ? undefined : "1px solid white")};
`;

const Layout = ({ children, location, fullWidth, noFooter }): ReactElement => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const lang = getLanguageFromPath(location.pathname);
  const siteTitle =
    lang === "zh-hant"
      ? "二階堂伊奧"
      : lang === "zh-hans"
      ? "二阶堂伊奥"
      : site.siteMetadata.title;

  return (
    <Flex flexDirection="column">
      <Header siteTitle={siteTitle} location={location} />
      <ContentWrapper fullWidth={fullWidth}>
        <Main fullWidth={fullWidth}>{children}</Main>
      </ContentWrapper>
      {!noFooter && (
        <ContentWrapper>
          <Footer />
        </ContentWrapper>
      )}
    </Flex>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  noFooter: PropTypes.bool,
};

Layout.defaultProps = {
  fullWidth: false,
  noFooter: false,
};

export default Layout;

const regexSpecialCharactersRegex = /([\^$.+?|{}\[\]()\\<>])/g;
const escapeRegex = (exp: string): string =>
  exp.replace(regexSpecialCharactersRegex, "\\$1");

let languagesCache = [];
let languagesRegexCache: RegExp = null;

export const parseSlug = (
  slug: string,
  languages: string[]
): { slugOnly: string; language: string | null } => {
  if (languages.length === 0) {
    return { slugOnly: slug, language: null };
  } else if (languages !== languagesCache) {
    languagesCache = languages;
    languagesRegexCache = new RegExp(
      `^(.+)\.(${languages
        .map((lang): string => escapeRegex(lang))
        .join("|")})$`
    );
  }

  const result = languagesRegexCache.exec(slug);
  languagesRegexCache.lastIndex = 0;

  if (result === null) {
    return { slugOnly: slug, language: null };
  } else {
    return { slugOnly: result[1], language: result[2] };
  }
};

/* eslint-disable import/export */
export function getLanguageFromPath(path: string): string;
export function getLanguageFromPath(
  path: string,
  languages: string[]
): string | null;

export function getLanguageFromPath(
  path,
  languages = undefined
): string | null {
  const languagePart = (path.startsWith("/") ? path.substring(1) : path).split(
    "/",
    1
  )[0];
  if (languages === undefined) {
    return languagePart;
  } else {
    return languages.includes(languagePart) ? languagePart : null;
  }
}
/* eslint-enable import/export */

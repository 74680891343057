import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";

import { getLanguageFromPath } from "../helpers/languageHelper";
import ContentWrapper from "./contentWrapper";
import Link from "./link";
import MainMenu from "./mainMenu";

const SiteTitle = styled(Box)`
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1em;
`;

const HeaderBlock = (props): ReactElement => (
  <Box as="header" bg="black" {...props} />
);

const StyledHeaderBlock = styled(HeaderBlock)`
  font-family: "Montserrat", sans-serif;
`;

const Header = ({ siteTitle, location }): ReactElement => (
  <StyledHeaderBlock>
    <ContentWrapper width={1} py={3}>
      <Flex
        flexDirection={["column", "row", "row"]}
        justifyContent="space-between"
      >
        <SiteTitle fontSize={4} mb={[3, 0]} pr={2}>
          <Link
            to={`/${getLanguageFromPath(location.pathname)}/`}
            style={{
              color: `white`,
            }}
          >
            {siteTitle}
          </Link>
        </SiteTitle>
        <MainMenu location={location} />
      </Flex>
    </ContentWrapper>
  </StyledHeaderBlock>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  location: PropTypes.object.isRequired,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default React.memo(Header);
